<template>
  <div>
    <!-- TODO ローディング中の表現はもっとちゃんとやる-->
    <div
      ref="loaderAnim"
      class="loading"
    >
      <div class="loader" />
    </div>

    <div class="wrapper">
      <slot name="above" />

      <canvas
        id="canvas"
        ref="canvas"
      />

      <slot name="below" />
    </div>
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

export default {
  // NOTE: これを参考 https://tympanus.net/codrops/2019/10/14/how-to-create-an-interactive-3d-character-with-three-js/
  setup() {
    const canvas = ref(null)
    const loaderAnim = ref(null)
    const scene = ref(null)
    const renderer = ref(null)
    const camera = ref(null)
    const model = ref(null)

    // NOTE: glbファイルはdefaultつけないとうまくいかない
    const modelPath = require('@/assets/images/glb/yonazuku.glb').default
    const bgColor = '#1F1D30'
    const lightColor = 0xffffff

    const init = () => {
      // Init the scene
      scene.value = new THREE.Scene()
      scene.value.background = new THREE.Color(bgColor)
      scene.value.fog = new THREE.Fog(bgColor, 60, 100)

      // Init the renderer
      renderer.value = new THREE.WebGLRenderer({
        canvas: canvas.value,
        antialias: true,
      })
      renderer.value.shadowMap.enabled = true
      renderer.value.setPixelRatio(window.devicePixelRatio)
      document.body.appendChild(renderer.value.domElement)

      // Add a camera
      camera.value = new THREE.PerspectiveCamera(
        50,
        window.innerWidth / window.innerHeight,
        0.1,
        1000,
      )
      camera.value.position.z = 30
      camera.value.position.x = 0
      camera.value.position.y = -3

      // Load a model
      const loader = new GLTFLoader()
      loader.load(
        modelPath,
        gltf => {
          model.value = gltf.scene

          model.value.traverse(o => {
            if (!o.isMesh) return

            // eslint-disable-next-line no-param-reassign
            o.castShadow = true
            // eslint-disable-next-line no-param-reassign
            o.receiveShadow = true
          })

          model.value.scale.set(444, 444, 444)
          model.value.position.y = -8

          scene.value.add(model.value)

          loaderAnim.value.remove()
        },
        undefined,
        error => console.error(error),
      )

      // Add lights
      const hemiLight = new THREE.HemisphereLight(lightColor, lightColor, 0.71)
      hemiLight.position.set(0, 50, 0)
      // Add hemisphere light to scene
      scene.value.add(hemiLight)

      const d = 8.25
      const dirLight = new THREE.DirectionalLight(lightColor, 0.74)
      dirLight.position.set(-8, 12, 8)
      dirLight.castShadow = true
      dirLight.shadow.mapSize = new THREE.Vector2(1024, 1024)
      dirLight.shadow.camera.near = 0.1
      dirLight.shadow.camera.far = 1500
      dirLight.shadow.camera.left = d * -1
      dirLight.shadow.camera.right = d
      dirLight.shadow.camera.top = d
      dirLight.shadow.camera.bottom = d * -1
      // Add directional Light to scene
      scene.value.add(dirLight)

      // Floor
      const floorGeometry = new THREE.PlaneGeometry(5000, 5000, 1, 1)
      const floorMaterial = new THREE.MeshPhongMaterial({
        color: bgColor,
        shininess: 0,
      })
      const floor = new THREE.Mesh(floorGeometry, floorMaterial)
      floor.rotation.x = -0.5 * Math.PI
      floor.receiveShadow = true
      floor.position.y = -11
      scene.value.add(floor)

      const grid = new THREE.GridHelper(666, 222)
      grid.position.y = -11
      scene.value.add(grid)
    }

    const resizeRendererToDisplaySize = rendererValue => {
      const width = window.innerWidth
      const height = window.innerHeight
      const canvasPixelWidth = rendererValue.domElement.width / window.devicePixelRatio
      const canvasPixelHeight = rendererValue.domElement.height / window.devicePixelRatio

      const needResize = canvasPixelWidth !== width || canvasPixelHeight !== height
      if (needResize) rendererValue.setSize(width, height, false)

      return needResize
    }

    const update = () => {
      if (resizeRendererToDisplaySize(renderer.value)) {
        camera.value.aspect = renderer.value.domElement.clientWidth / renderer.value.domElement.clientHeight
        camera.value.updateProjectionMatrix()
      }

      renderer.value.render(scene.value, camera.value)
      requestAnimationFrame(update)
    }

    const getMousePos = e => {
      return { x: e.clientX, y: e.clientY }
    }

    const getMouseDegrees = (x, y, degreeLimit) => {
      let dx = 0
      let dy = 0
      let xdiff
      let xPercentage
      let ydiff
      let yPercentage

      const w = { x: window.innerWidth, y: window.innerHeight }

      // Left (Rotates model left between 0 and -degreeLimit)

      // 1. If cursor is in the left half of screen
      if (x <= w.x / 2) {
        // 2. Get the difference between middle of screen and cursor position
        xdiff = w.x / 2 - x
        // 3. Find the percentage of that difference (percentage toward edge of screen)
        xPercentage = (xdiff / (w.x / 2)) * 100
        // 4. Convert that to a percentage of the maximum rotation we allow for the neck
        dx = ((degreeLimit * xPercentage) / 100) * -1
      }

      // Right (Rotates model right between 0 and degreeLimit)
      if (x >= w.x / 2) {
        xdiff = x - w.x / 2
        xPercentage = (xdiff / (w.x / 2)) * 100
        dx = (degreeLimit * xPercentage) / 100
      }

      // Up (Rotates model up between 0 and -degreeLimit)
      if (y <= w.y / 2) {
        ydiff = w.y / 2 - y
        yPercentage = (ydiff / (w.y / 2)) * 100
        // Note that I cut degreeLimit in half when she looks up
        dy = (((degreeLimit * 0.5) * yPercentage) / 100) * -1
      }

      // Down (Rotates model down between 0 and degreeLimit)
      if (y >= w.y / 2) {
        ydiff = y - w.y / 2
        yPercentage = (ydiff / (w.y / 2)) * 100
        dy = (degreeLimit * yPercentage) / 100
      }

      return { x: dx, y: dy }
    }

    const moveModel = (mouse, degreeLimit) => {
      if (!model.value) return

      const degrees = getMouseDegrees(mouse.x, mouse.y, degreeLimit)
      model.value.rotation.y = THREE.Math.degToRad(degrees.x)
      model.value.rotation.x = THREE.Math.degToRad(degrees.y)
    }

    const mouseMoveListener = e => {
      const mousecoords = getMousePos(e)
      moveModel(mousecoords, 20)
    }

    onMounted(() => {
      init()
      update()
      document.addEventListener('mousemove', mouseMoveListener)
    })

    onUnmounted(() => {
      document.removeEventListener('mousemove', mouseMoveListener)
    })

    return {
      canvas,
      loaderAnim,
    }
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#canvas {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.loading {
  position: fixed;
  z-index: 50;
  width: 100%;
  height: 100%;
  top: 0; left: 0;
  background: #27243B;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader{
  -webkit-perspective: 120px;
  -moz-perspective: 120px;
  -ms-perspective: 120px;
  perspective: 120px;
  width: 100px;
  height: 100px;
}

.loader:before{
  content: "";
  position: absolute;
  left: 25px;
  top: 25px;
  width: 50px;
  height: 50px;
  background-color: white;
  animation: flip 1s infinite;
}

@keyframes flip {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(180deg)  rotateX(180deg);
  }
}
</style>
