<template>
  <div>
    <header class="login-header py-8 px-6">
      <v-img
        :src="appLogoTypography"
        max-height="30px"
        max-width="160px"
        :alt="appName"
        style="position: absolute; z-index: 1000"
      ></v-img>
    </header>

    <yonazuku-3d>
      <template #below>
        <v-card
          elevation="10"
          class="auth-card mb-8"
        >
          <v-card-text>
            <v-form
              ref="loginForm"
              style="width: 18rem;"
              @submit.prevent="handleFormSubmit"
            >
              <v-text-field
                v-model="email"
                outlined
                label="Eメール"
                placeholder="yonarezi@example.com"
                hide-details
                autocomplete="username"
                class="mb-3"
              ></v-text-field>

              <v-text-field
                v-model="password"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                label="パスワード"
                placeholder="············"
                :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                hide-details
                autocomplete="current-password"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>

              <v-btn
                block
                :ripple="false"
                :loading="loggingIn"
                :disabled="loggingIn"
                type="submit"
                color="primary"
                class="mt-6"
              >
                <v-icon left>
                  {{ icons.mdiLoginVariant }}
                </v-icon>
                ログイン
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </template>
    </yonazuku-3d>
  </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line object-curly-newline
import {
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiLoginVariant,
} from '@mdi/js'
import { ref, getCurrentInstance } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import camelcaseKeys from 'camelcase-keys'
import { signIn } from '@/api/auth'
import Yonazuku3d from '@/views/components/util/Yonazuku3d.vue'

export default {
  components: {
    Yonazuku3d,
  },
  setup() {
    const loginForm = ref(null)
    const loggingIn = ref(false)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()

    const isPasswordVisible = ref(false)

    const email = ref('')
    const password = ref('')
    const errorMessages = ref([])

    const handleFormSubmit = async () => {
      loggingIn.value = true

      const isFormValid = loginForm.value.validate()
      if (!isFormValid) return

      await signIn(email.value, password.value)
        .then(response => {
          const { user, club, abilities } = camelcaseKeys(response.data, { deep: true })
          localStorage.setItem('currentUser', JSON.stringify(user))
          localStorage.setItem('currentClub', JSON.stringify(club))

          // Set user ability
          // ? https://casl.js.org/v5/en/guide/intro#update-rules
          vm.$ability.update(abilities)
          localStorage.setItem('userAbilities', JSON.stringify(abilities))

          // On success redirect to home
          router.go('/')

          loggingIn.value = false
        })
        .catch(error => {
          // TODO: error対応
          console.error('Oops, Unable to login!')
          console.log('error :>> ', error.response)
          errorMessages.value = error.response?.data?.error

          loggingIn.value = false

          vm.$toast.error('ログインに失敗しました')
        })
    }

    return {
      loggingIn,
      handleFormSubmit,
      isPasswordVisible,
      email,
      password,
      errorMessages,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiLoginVariant,
      },
      validators: {
        required,
        emailValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogoTypography: themeConfig.app.logoTypography,

      // Template Refs
      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
.login-header {
  position: absolute;
  top: 0;
  z-index: 999;
}
.auth-card {
  z-index: 1;
  position: absolute;
  bottom: 10%
}
</style>
